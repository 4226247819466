.submit-btn {
    background: #1765d8;
    color: #fff;
    position: relative;
    height: 60px;
    padding: 16px 15px 15px 75px;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
}

.submit-btn:hover {
    box-shadow: 2px 2px 10px #888;
    transition: all .3s;
}

.submit-btn .btn-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
}

.submit-btn .btn-txt {
    margin-right: 50px;
}

.submit-btn .MuiCircularProgress-root {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
}
