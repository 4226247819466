header.header-wrapper {
    background: #3f51b5;
    height: 64px;
    padding: 13px 20px 10px;
}
  
.fl {
    float: left;
}
  
.fr {
    float: right;
}
  
.logo-wrapper {
    height: 35px;
}
  
.logo-wrapper .logo-name, .login-name-wrapper .login-name {
    font-size: 20px;
    color: #fff;
    margin: 5px 12px;
    line-height: 24px;
    font-weight: 500;
}
  
.login-name-wrapper .login-img {
    width: 19px;
    height: 19px;
    margin: 9px 5px 9px 0;
    cursor: pointer;
}

.login-name-wrapper .login-img:hover {
    box-shadow: 0 0 10px #fff;
    transition: all .3s;
}
