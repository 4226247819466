.signup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
}

.signup-wrapper .signup-block {
    background: #fff;
    width: 960px;
    height: 709px;
    padding: 45px;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px #c3c3c3;
    border-radius: 4px;
}

.signup-left, .signup-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signup-left {
    padding-left: 0;
}

.signup-right {
    padding-right: 0;
}

.signup-left .sign-in-txt {
    color: #212529;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 36px;
}

.signup-right .signup-illustration img {
    width: 100%;
}

.sign-up-form-wrapper .form-input-wrapper {
    height: 78px;
    margin: 10px 0px;
}

.sign-up-form-wrapper .form-input-wrapper.opt-in-block {
    height: 50px;
}

.sign-up-form-wrapper .form-input-wrapper .form-input {
    width: 100%;
}

.sign-up-form-wrapper .bottom-actions-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 8px;
    font-size: 14px;
    color: #1976D2;
}

.sign-up-form-wrapper .bottom-actions-wrapper .sign-up-link {
    cursor: pointer;
}

.sign-up-form-wrapper .form-input-wrapper .opt-in-wrapper p {
    margin: auto 0px
}

.sign-up-form-wrapper .form-input-wrapper .opt-in-wrapper .opt-in-input-wrapper {
    display: flex;
}
.sign-up-form-wrapper .form-input-wrapper .opt-in-input-wrapper .MuiCheckbox-root {
    padding: 4px 8px 4px 0px;
}

.sign-up-form-wrapper .form-input-wrapper .opt-in-error {
    color: #f44336;
    padding-left: 14px;
    font-size: 12px;
}
