.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
}

.login-wrapper .login-block {
    background: #fff;
    width: 960px;
    height: 508px;
    padding: 45px;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px #c3c3c3;
    border-radius: 4px;
}

.login-left, .login-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-left {
    padding-left: 0;
}

.login-right {
    padding-right: 0;
}

.login-left .sign-in-txt {
    color: #212529;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 36px;
}

.login-right .login-illustration img {
    width: 100%;
}

.sign-in-form-wrapper .form-input-wrapper {
    height: 78px;
    margin: 10px 0px;
}

.sign-in-form-wrapper .form-input-wrapper .form-input {
    width: 100%;
}

.sign-in-form-wrapper .bottom-actions-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 8px;
    font-size: 14px;
    color: #1976D2;
}

.sign-in-form-wrapper .bottom-actions-wrapper .sign-up-link {
    cursor: pointer;
    text-decoration: none;
}
