.loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
}

.loading-wrapper .loading-block {
    height: 397px;
    width: 480px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 3px #263f3f44;
}

.loading-wrapper .loading-block img {
    height: 248px;
    width: 282px;
    margin: 34px 99px;
}

.loading-wrapper .loading-block .loading-text {
    margin-top: 2px;
    text-align: center;
    line-height: 20px;
    font-weight: 500;
    font-size: 22px;
}