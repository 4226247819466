.thank-you-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
}

.thank-you-wrapper .thank-you-block {
    background: #fff;
    width: 960px;
    height: 508px;
    padding: 45px;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px #c3c3c3;
    border-radius: 4px;
}

.thank-you-left, .thank-you-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.thank-you-left {
    padding-left: 0;
}

.thank-you-right {
    padding-right: 0;
}

.thank-you-left .thank-you-txt-large {
    color: #212529;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 36px;
}

.thank-you-right .thank-you-illustration img {
    width: 100%;
}