.iframe-content-styling {
    font-family: 'Roboto', sans-serif;
    line-height: 22px;
}

.iframe-content-styling h1 {
    color: #304351;
    font-size: 20px;
}

.iframe-content-styling h1.head-title {
    font-size: 22px;
    line-height: 28px;
}

.iframe-content-styling h2, .iframe-content-styling h3 {
    color: #304351;
    font-size: 16px;
    margin: 20px 0 0px;
}

.iframe-content-styling p, .iframe-content-styling ul li, .iframe-content-styling p strong {
    color: #727e88;
    font-size: 14px;
    margin-top: 5px;
}

.iframe-content-styling.article p strong {
    color: #304351;
    font-size: 16px;
}

.iframe-content-styling.article p {
    margin-bottom: 12px;
}

.iframe-content-styling ul {
    padding-left: 15px;
}

.iframe-wrapper .container {
    width: 800px;
    padding: 50px 0 30px;
    margin: 0 auto;
}

.text-underline {
    text-decoration: underline;
}

#publisher-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#publisher-table td, #publisher-table th {
    border: 1px solid #ddd;
    padding: 8px;
}

#publisher-table td.width30 {
    width: 18%;
}
#publisher-table td.width70 {
    color: #565656;
}

#publisher-table tr:nth-child(even){
    background-color: #f2f2f2;
}

#publisher-table tr:hover {
    background-color: #e4e4e4;
}

#publisher-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8e8e8e;
    color: white;
    width: 50%;
}

@media (max-width: 1024px) {
    .iframe-wrapper .container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
}